import axios from 'axios'
import {Notification} from 'element-ui'
import {getToken} from '@/utils'
import router from '@/router'

// 创建axios实例
const service = axios.create({
    // baseURL: process.env.NODE_ENV === 'production' ? '/' : '/', // api 的 base_url
    timeout: 1200000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        config.headers['Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)
// response 拦截器
service.interceptors.response.use(
    response => {
        if (response.data.status === '4000') {
            router.push('/login')
            return Promise.reject('error')
        } else if (response.data.status !== '2000' && '[object Blob]' !== response.data.toString()) {
            Notification.error({
                title: response.data.message
            })
            return Promise.reject('error')
        } else {
            return response.data
        }
    },
    error => {
        return Promise.reject(error)
    }
)
export default service

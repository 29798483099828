import request from '@/utils/request'

//登录（用户相关接口）
export function userLoginApi(data) {
    return request({
        url: '/fd/onlineBak/user/login',
        method: 'post',
        data
    })
}

//获取加密公钥
export function getPublicKeyApi(params) {
    return request({
        url: '/fd/onlineBak/user/getPublicKey',
        method: 'get',
        query: params
    })
}

//修改密码
export function alterPasswordApi(data) {
    return request({
        url: '/fd/onlineBak/user/alterPassword',
        method: 'post',
        data
    })
}

//新增后台用户（超管相关接口）
export function addSysUserApi(data) {
    return request({
        url: '/fd/onlineBak/admin/addSysUser',
        method: 'post',
        data
    })
}

//修改后台用户信息
export function modifyUserApi(data) {
    return request({
        url: '/fd/onlineBak/admin/modifyUser',
        method: 'post',
        data
    })
}

//修改用户状态(正常,冻结,删除)
export function changeUserStatusApi(data) {
    return request({
        url: '/fd/onlineBak/admin/changeUserStatus',
        method: 'post',
        data
    })
}

//获取后台账户列表
export function getSysUserListApi(data) {
    return request({
        url: '/fd/onlineBak/admin/getSysUserList',
        method: 'post',
        data
    })
}

// 导出系统账号列表
export function exportSysUserApi(data) {
    return request({
        url: '/fd/onlineBak/admin/exportSysUser',
        method: 'post',
        responseType: 'blob',
        data
    })
}

//公司列表（公司相关接口）
export function getCompanyListApi(params) {
    return request({
        url: '/fd/onlineBak/company/getCompanyList',
        method: 'get',
        query: params
    })
}

//修改公司状态
export function changeCompanyStatusApi(data) {
    return request({
        url: '/fd/onlineBak/company/changeCompanyStatus',
        method: 'post',
        data
    })
}

//新增公司
export function addCompanyApi(data) {
    return request({
        url: '/fd/onlineBak/company/addCompany',
        method: 'post',
        data
    })
}

//修改公司
export function modifyCompanyApi(data) {
    return request({
        url: '/fd/onlineBak/company/modifyCompany',
        method: 'post',
        data
    })
}

//获取公司架构树
export function getCompanyTreeApi(data) {
    return request({
        url: '/fd/onlineBak/company/getCompanyTree',
        method: 'post',
        data
    })
}

// 修改架构名称
export function modifyLevelNameApi(data) {
    return request({
        url: '/fd/onlineBak/company/modifyLevelName',
        method: 'post',
        data
    })
}

// 添加下一级架构
export function addLevelApi(data) {
    return request({
        url: '/fd/onlineBak/company/addLevel',
        method: 'post',
        data
    })
}

// 添加同级架构
export function addSameLevelApi(data) {
    return request({
        url: '/fd/onlineBak/company/addSameLevel',
        method: 'post',
        data
    })
}

// 删除架构
export function delLevelApi(data) {
    return request({
        url: '/fd/onlineBak/company/delLevel',
        method: 'post',
        data
    })
}

// 获取巡检人员管理基础信息（巡检人员相关接口）
export function getFrontManagerBaseApi(params) {
    return request({
        url: '/fd/onlineBak/front/getFrontManagerBase',
        method: 'get',
        query: params
    })
}

//巡检人员分页查询
export function frontUserSearchApi(data) {
    return request({
        url: '/fd/onlineBak/front/frontUserSearch',
        method: 'post',
        data
    })
}

//新增巡检人员
export function addFrontUserApi(data) {
    return request({
        url: '/fd/onlineBak/front/addFrontUser',
        method: 'post',
        data
    })
}

// 修改巡检人员信息
export function modifyFrontUserApi(data) {
    return request({
        url: '/fd/onlineBak/front/modifyFrontUser',
        method: 'post',
        data
    })
}

//增加设备与人员绑定
export function bindUserDeviceApi(data) {
    return request({
        url: '/fd/onlineBak/front/bindUserDevice',
        method: 'post',
        data
    })
}

//修改前台用户状态(正常,冻结,删除)
export function frontChangeUserStatusApi(data) {
    return request({
        url: '/fd/onlineBak/front/changeUserStatus',
        method: 'post',
        data
    })
}

//删除设备绑定
export function delDeviceBindApi(data) {
    return request({
        url: '/fd/onlineBak/front/delDeviceBind',
        method: 'post',
        data
    })
}

//巡检人员导出
export function frontUserExportApi(data) {
    return request({
        url: '/fd/onlineBak/front/frontUserExport',
        method: 'post',
        responseType: 'blob',
        data
    })
}

//关联设备的列表分页查询
export function getBindDeviceListApi(data) {
    return request({
        url: '/fd/onlineBak/front/getBindDeviceList',
        method: 'post',
        data
    })
}

//获取可添加绑定的设备
export function getUnBindDeviceListApi(data) {
    return request({
        url: '/fd/onlineBak/front/getUnBindDeviceList',
        method: 'post',
        data
    })
}

// 导出公司列表
export function exportCompanyListApi(data) {
    return request({
        url: '/fd/onlineBak/company/exportCompanyList',
        method: 'get',
        responseType: 'blob',
        data
    })
}

// 新增设备（设备管理相关接口）
export function addDeviceApi(data) {
    return request({
        url: '/fd/onlineBak/device/addDevice',
        method: 'post',
        data
    })
}

// 删除设备
export function delDeviceApi(data) {
    return request({
        url: '/fd/onlineBak/device/delDevice',
        method: 'post',
        data
    })
}

// 获取设备详情
export function getDeviceDetailApi(data) {
    return request({
        url: '/fd/onlineBak/device/getDeviceDetail',
        method: 'post',
        data
    })
}

// 设备关联巡检人员
export function deviceBindUserApi(data) {
    return request({
        url: '/fd/onlineBak/device/deviceBindUser',
        method: 'post',
        data
    })
}

// 获取设备管理列表导出
export function deviceListExportApi(data) {
    return request({
        url: '/fd/onlineBak/device/deviceListExport',
        method: 'post',
        data
    })
}

// 获取新增设备所需信息
export function getAddDeviceBaseApi(params) {
    return request({
        url: '/fd/onlineBak/device/getAddDeviceBase',
        method: 'get',
        query: params
    })
}

// 获取设备管理列表分页
export function getDeviceListApi(data) {
    return request({
        url: '/fd/onlineBak/device/getDeviceList',
        method: 'post',
        data
    })
}

// 获取设备管理列表基础信息
export function getDeviceSearchBaseApi(params) {
    return request({
        url: '/fd/onlineBak/device/getDeviceSearchBase',
        method: 'get',
        query: params
    })
}

// 获取设备已关联的巡检人员分页
export function getFrontUserBindListPageApi(data) {
    return request({
        url: '/fd/onlineBak/device/getFrontUserBindListPage',
        method: 'post',
        data
    })
}

// 获取设备可关联的巡检人员分页
export function deviceGetFrontUserListApi(data) {
    return request({
        url: '/fd/onlineBak/device/getFrontUserList',
        method: 'post',
        data
    })
}

// 获取历史温度分页
export function getTemperaturePageApi(data) {
    return request({
        url: '/fd/onlineBak/device/getPhaseHistoryPage',
        method: 'post',
        data
    })
}

// 获取历史局放分页
export function getDischargePageApi(data) {
    return request({
        url: '/fd/onlineMini/device/getDischargePage',
        method: 'post',
        data
    })
}

// 修改设备信息
export function modifyDeviceApi(data) {
    return request({
        url: '/fd/onlineBak/device/modifyDevice',
        method: 'post',
        data
    })
}

// 导出设备列表
export function exportDeviceApi(data) {
    return request({
        url: '/fd/onlineBak/device/deviceListExport',
        method: 'post',
        responseType: 'blob',
        data
    })
}

// 导出设备详情
export function exportDeviceDetailApi(data) {
    return request({
        url: '/fd/onlineBak/device/exportDeviceDetail',
        method: 'post',
        responseType: 'blob',
        data
    })
}

// 获取权限申请审核列表基础数据
export function getApplyAuditBaseApi(params) {
    return request({
        url: '/fd/onlineBak/device/getApplyAuditBase',
        method: 'get',
        query: params
    })
}

// 权限申请审核列表
export function applyAuditListApi(data) {
    return request({
        url: '/fd/onlineBak/device/applyAuditList',
        method: 'post',
        data
    })
}

// 权限审核
export function operationApplyApi(data) {
    return request({
        url: '/fd/onlineBak/device/operationApply',
        method: 'post',
        data
    })
}

